import * as React from 'react'
import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo/seo'
import BackgroundSlider from 'react-background-slider'

// markup
const Hosgeldiniz = () => {
  return (
    <Layout>
      <Seo
        title='Sayın Textile'
        description='Sayın textile'
        article
        lang='tr'
      />
      <div className='w-full'>
        <BackgroundSlider
          images={[
            '/images/slayt-1.jpg',
            '/images/slayt-2.jpg',
            '/images/slayt-3.jpg',
          ]}
          duration={10}
          transition={2}
        />

        <div className='container mx-auto my-48 flex flex-col items-center content-center'>
          <StaticImage
            src='../images/sayintextile-big.png'
            alt='sayın textile logo'
            width='500'
            className='mx-auto '
          />

          <div className='bg-gray-800 text-white px-5 py-3 bg-opacity-50 my-10 max-w-screen-sm text-center mx-auto'>
            <p className='my-2'>
              İstenen ebat, desen ve renkte özel dikili üretim
            </p>
            <p className='my-2'>390cm tarak enine kadar ham kumaş üretimi</p>
            <p className='my-2'>
              Geniş yelpazeye sahip Renkli Saten ve Renkli Akfil koleksiyonu
            </p>
          </div>
        </div>

        <div className='bg-gray-900 h-60 py-10'>
          <Container className='text-center'>
            <h2 className='text-5xl mb-10 text-white'>
              Sayin Tekstil'e Hoşgeldiniz
            </h2>
            <StaticImage src='../images/hr1.png' />
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default Hosgeldiniz
